import { ARTIFACT_VERSION } from '../../lib/contants';

const SENTRY_DSN = 'https://e084a021a3634396ad8c1b30a6e16f4f@sentry.io/1517471';

interface ISentryConfig {
  enabled?: boolean;
  release?: string;
  environment: string;
}

export interface ISentryOptions {
  dsn: string;
  config: ISentryConfig;
}

export type IReportError = (error: Error | ErrorEvent | string) => void;

export const buildSentryOptions = (config: ISentryConfig): ISentryOptions => {
  return {
    dsn: SENTRY_DSN,
    config: {
      enabled: process.env.NODE_PATH === 'production',
      release: ARTIFACT_VERSION,
      ...config,
    },
  };
};
